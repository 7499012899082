<template>
	<div>
		<div class="osahan-cart">
			<div class="p-3 border-bottom">
				<div class="d-flex align-items-center">
					<h5 class="font-weight-bold m-0">Cart</h5>
				</div>
			</div>
			<div class="osahan-body">
				<div
					v-for="(line, key) in commands.filter(
						el => el.products.length > 0
					)"
					:key="key"
					class="cart-items bg-white position-relative border-bottom"
				>
					<a
						v-if="line.price.promoPrice != line.price.price"
						class="position-absolute"
					>
						<span class="badge badge-danger m-3"
							>-{{
								Math.round(
									Number(
										(line.price.promoPrice /
											line.price.price) *
											100
									)
								)
							}}%</span
						>
					</a>
					<div class="d-flex  align-items-center p-3">
						<a href="#"
							><div
								:style="
									`background-image: url(${$constants.STORAGE_PATH}${line.products[0].image})`
								"
								class="img-fluid bg-cover"
						/></a>
						<div class="ml-3 text-dark text-decoration-none w-100">
							<h5 class="mb-1">
								{{ line.products[0].label }}
							</h5>
							<p class="text-muted mb-2">
								<del
									v-if="
										line.price.promoPrice !=
											line.price.price
									"
									class="text-success mr-1"
									>{{ line.price.price }} {{ currency }}</del
								>
								{{ line.price.promoPrice }} {{ currency }}
							</p>
							<div class="d-flex align-items-center">
								<div
									class="input-group input-spinner ml-auto cart-items-number"
								>
									<div class="input-group-prepend">
										<button
											class="btn btn-success btn-sm"
											type="button"
											@click="increment(key)"
										>
											+
										</button>
									</div>
									<input
										type="text"
										class="form-control"
										readonly="readonly"
										v-model="line.quantity"
									/>
									<div class="input-group-append">
										<button
											class="btn btn-success btn-sm"
											type="button"
											@click="decrement(key)"
										>
											−
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="p-3 mt-2">
					<button
						@click="$emit('validated')"
						class="text-decoration-none w-full"
					>
						<div
							class="rounded shadow bg-success d-flex align-items-center p-3 text-white"
						>
							<div class="more">
								<h6 class="m-0">
									Total {{ total }} {{ currency }}
								</h6>
								<!-- <p class="small m-0">remise $1.52</p> -->
							</div>
							<div class="ml-auto">
								<i class="icofont-simple-right"></i>
							</div>
						</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: ['commands', 'total'],
	data: () => ({}),
	mounted: function() {},
	computed: {
		...mapGetters({
			currency: 'lang/getCurrency'
		})
	},
	methods: {
		increment: function(index) {
			let command = this.commands[index];
			let quantity = command.quantity;
			if (quantity > 99) quantity = 99;
			else quantity += 1;
			this.$store.dispatch('cart/addCommandToCartNoCheck', {
				price: command.price,
				products: command.products,
				quantity
			});
		},
		decrement: function(index) {
			let command = this.commands[index];
			let quantity = command.quantity;
			if (quantity == 0) quantity = 0;
			else quantity -= 1;
			this.$store.dispatch('cart/addCommandToCartNoCheck', {
				price: command.price,
				products: command.products,
				quantity
			});
		}
	}
};
</script>

<style></style>
